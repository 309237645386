import Text from 'components/text';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DATE_RANGE = ['1W', '1M', '2M', '3M', '6M'];

export default function SocialAnalyticsTimeline({
  selected,
  setSelectedDate,
}: {
  selected: string;
  setSelectedDate: (d: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <Box
      borderBottom={1}
      borderColor="#ddd"
      paddingX={2}
      paddingY={1}
      display="flex"
      gap={2}
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize={20}>{t('Timeline')}</Text>
      <Box display="flex" gap={1} fontSize={16}>
        {DATE_RANGE.map((range) => {
          const isSelected = range === selected;

          return (
            <Box
              key={range}
              border={1}
              paddingY={0.5}
              paddingX={4}
              role="button"
              borderRadius={1.5}
              borderColor={isSelected ? '#7801ef' : '#ccc'}
              sx={{
                cursor: 'pointer',
                userSelect: 'none',
                ':hover': {
                  borderColor: '#7801ef',
                },
                ':active': {
                  backgroundColor: '#ddd',
                },
              }}
              onClick={() => setSelectedDate(range)}
            >
              <Text color={isSelected ? '#7801ef' : '#666'} lineHeight={1.3}>
                {range}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
